/* eslint-disable camelcase */
import React from 'react'

import { Layout } from '@leshen/gatsby-theme-contentful'
import {
  Brandy,
  Hero,
  Image,
  ItemBank,
  LeshenPhoneCTA,
  Link,
  LinkButton,
  List,
  ListItem,
  ModalWithState,
  Price,
  SplitContent,
  VariableContent,
} from '@leshen/gatsby-theme-leshen'
import { Typography } from '@leshen/ui'
import { Link as GatsbyLink, graphql } from 'gatsby'

import SeoCtaBar from '../components/SeoCtaBar'

const CityTemplate = ({ data }) => {
  const {
    Meta_Description,
    City,
    State,
    State_Abbreviation,
    Promo_Code,
    Page_Path,
    Meta_Title,
  } = data.pageInfo.edges[0].node

  const stateAbbreviation = State_Abbreviation.toLowerCase()

  const canonicalCityUrl = City.toLowerCase().replace(/\s+/g, '-')

  const passedData = {
    ...data,
    contentfulPage: {
      header: data.allContentfulHeader.edges[0].node,
      footer: data.allContentfulFooter.edges[0].node,
      seo: {
        canonical: `/availability/${stateAbbreviation}/${canonicalCityUrl}`,
        description: Meta_Description,
        robots: 'index,follow',
        title: Meta_Title,
        titleTemplate: '',
      },
      path: Page_Path,
      promoCode: Promo_Code,
      customBreadCrumbs: [
        {
          shortName: 'Home',
          path: '/',
        },
        {
          shortName: 'Availability',
          path: '/availability',
        },
        {
          shortName: State_Abbreviation,
          path: `/availability/${stateAbbreviation.toLowerCase()}`,
        },
      ],
    },
  }

  const cityDropdownData = data.cities.edges.map((current) => ({
    label: current.node.City,
    value: current.node.Page_Path,
  }))

  return (
    <Layout
      data={passedData}
      main={
        <>
          <Hero
            customLink={GatsbyLink}
            backgroundColor="Primary"
            mainContent={
              <>
                <Typography variant="h1">
                  Switch to DIRECTV in {City}, {State}
                </Typography>
                <List>
                  <ListItem>
                    <Typography variant="h5" rendersAs="span">
                      <Typography>
                        3 months of Max, Paramount+ with SHOWTIME<sup>®</sup>,
                        STARZ<sup>®</sup>, MGM+<sup>™</sup> and Cinemax
                        <sup>®</sup> included at no extra charge.
                      </Typography>
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography variant="h5" rendersAs="span">
                      <Typography>Free premium channels</Typography>
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography variant="h5" rendersAs="span">
                      <Typography>
                        Genie<sup>®</sup> HD DVR included
                      </Typography>
                    </Typography>
                  </ListItem>
                </List>
                <Price
                  standalone
                  className="price embedded-entry"
                  {...data.brandyPackage?.price}
                  bullets={data.brandyPackage.bullets}
                  variant="Default"
                >
                  <ModalWithState modalText="See offer details" heading="">
                    <Brandy
                      symbol={data.priceModalDisclaimer.symbol}
                      text={data.priceModalDisclaimer.text}
                      variant="legal"
                    />
                  </ModalWithState>
                </Price>
                <LeshenPhoneCTA variant="hero" color="tertiary">
                  Call
                </LeshenPhoneCTA>
                <Brandy
                  symbol={data.heroDisclaimer.symbol}
                  text={data.heroDisclaimer.text}
                  variant="legal"
                />
              </>
            }
            image={
              <Image
                data={{
                  ...data.heroDesktopImage?.cloudinary[0]?.gatsbyImageData,
                  context: data.heroDesktopImage?.cloudinary[0]?.context,
                }}
                isImageCritical
              />
            }
            mobileImage={
              <Image
                data={{
                  ...data.heroMobileImage?.cloudinary[0]?.gatsbyImageData,
                  context: data.heroMobileImage?.cloudinary[0]?.context,
                }}
                isImageCritical
              />
            }
          />
          <SeoCtaBar />
          <SplitContent
            customLink={GatsbyLink}
            backgroundColor="White"
            mainContent={
              <>
                <Typography variant="h2">
                  DIRECTV Channels In {City}, {State}
                </Typography>
                <Typography>
                  Does your family love watching movies? How about sports and
                  news coverage? Whatever you love, DIRECTV in {City}, {State}{' '}
                  has it all. Enjoy the most popular networks and programming,
                  and you can also receive DIRECTV On Demand service and a HD
                  DVR.
                </Typography>
                <Typography>
                  {`It's`} easy to pick your package. Just visit the DIRECTV
                  Channels page <GatsbyLink to="/channels">here</GatsbyLink>,
                  select the channels you’re interested in, and find the package
                  that best suits your home in {City}, {State}.
                </Typography>
                <LinkButton
                  to="/channels"
                  CustomLink={GatsbyLink}
                  color="primary"
                  outlined
                >
                  See Channel Lineup
                </LinkButton>
              </>
            }
            image={
              <Image
                data={{
                  ...data.channelDesktopImage?.cloudinary[0]?.gatsbyImageData,
                  context: data.channelDesktopImage?.cloudinary[0]?.context,
                }}
                isImageCritical
              />
            }
            mobileImage={
              <Image
                data={{
                  ...data.channelMobileImage?.cloudinary[0]?.gatsbyImageData,
                  context: data.channelMobileImage?.cloudinary[0]?.context,
                }}
                isImageCritical
              />
            }
          />
          <SplitContent
            customLink={GatsbyLink}
            backgroundColor="White"
            mainContent={
              <>
                <Typography variant="h2">
                  The Perks of DIRECTV in {State}
                </Typography>
                <Typography>
                  Looking for a better entertainment solution? Never miss your
                  favorite programs with DIRECTV!
                </Typography>
                <List>
                  <ListItem>
                    <Typography variant="h5" rendersAs="span">
                      <Typography>Simple installation</Typography>
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography variant="h5" rendersAs="span">
                      <Typography>Diverse programming catalog</Typography>
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography variant="h5" rendersAs="span">
                      <Typography>Multiple plans to choose from</Typography>
                    </Typography>
                  </ListItem>
                </List>
                <LinkButton
                  to="/packages"
                  CustomLink={GatsbyLink}
                  color="primary"
                  outlined
                >
                  Directv Packages
                </LinkButton>
              </>
            }
            image={
              <Image
                data={{
                  ...data.motherSonImage?.cloudinary[0]?.gatsbyImageData,
                  context: data.motherSonImage?.cloudinary[0]?.context,
                }}
                isImageCritical
              />
            }
          />
          <SplitContent
            customLink={GatsbyLink}
            backgroundColor="White"
            mainContent={
              <>
                <Typography variant="h2">
                  Score big with DIRECTV in {City}, {State}
                </Typography>
                <Typography>
                  Never miss a play and see all the live-action from your local
                  sports teams in {City}, {State} in HD!
                </Typography>
                <List>
                  <ListItem>
                    <Typography variant="h5" rendersAs="span">
                      <Typography>ESPN</Typography>
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography variant="h5" rendersAs="span">
                      <Typography>FOX</Typography>
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography variant="h5" rendersAs="span">
                      <Typography>TBS</Typography>
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography variant="h5" rendersAs="span">
                      <Typography>CBS Sports</Typography>
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography variant="h5" rendersAs="span">
                      <Typography>Plus additional channels</Typography>
                    </Typography>
                  </ListItem>
                </List>
                <LinkButton
                  to="/channels/sports"
                  CustomLink={GatsbyLink}
                  color="primary"
                  outlined
                >
                  View DIRECTV Sports Packages
                </LinkButton>
              </>
            }
            image={
              <Image
                data={{
                  ...data.sportsImage?.cloudinary[0]?.gatsbyImageData,
                  context: data.sportsImage?.cloudinary[0]?.context,
                }}
                isImageCritical
              />
            }
          />
          <VariableContent
            mainContent={
              <>
                <Typography variant="h2">Check Availability By City</Typography>
              </>
            }
            alignMainContent="Center"
            style={{ paddingTop: '0px' }}
          >
            <ItemBank className="city-link-bank">
              {cityDropdownData?.map((current, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <Link to={current.value} key={i}>
                  {current.label}
                </Link>
              ))}
            </ItemBank>
          </VariableContent>
        </>
      }
    />
  )
}

export default CityTemplate

export const query = graphql`
  query CityTemplateQuery($pagePath: String!, $stateAbbrev: String!) {
    site {
      ...LeshenMetadata
    }
    pageInfo: allDatasetManagerUsDirectAoa(
      filter: { Page_Path: { eq: $pagePath } }
    ) {
      edges {
        node {
          City
          State
          Promo_Code
          State_Abbreviation
          Page_Path
          Meta_Title
          Meta_Description
        }
      }
    }
    brandyPackage(brandy_id: { eq: "dtv-entertainment" }) {
      brand_token
      brandy_id
      name
      details
      bullets {
        text
        disclaimer {
          contentful_id
          symbol
          text
        }
      }
      price {
        currency
        amount
        sale
        prefix
        duration
        suffix
        before
        disclaimer {
          contentful_id
          symbol
          text
        }
      }
      comparison
      labelOffer
    }
    priceModalDisclaimer: brandyDisclaimer(
      brandy_id: { eq: "disclaimer-modal-entertainment" }
    ) {
      brandy_id
      symbol
      text
    }
    heroDisclaimer: brandyDisclaimer(brandy_id: { eq: "disclaimer-inbody" }) {
      brandy_id
      symbol
      text
    }
    cities: allDatasetManagerUsDirectAoa(
      filter: {
        State_Abbreviation: { eq: $stateAbbrev }
        Area_Type: { eq: "city" }
      }
    ) {
      edges {
        node {
          City
          Page_Path
        }
      }
    }
    heroDesktopImage: contentfulMedia(
      contentful_id: { eq: "41W6rjykh8PksPhv2tbGDn" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED, width: 2560)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    heroMobileImage: contentfulMedia(
      contentful_id: { eq: "4e3kyBxiE5Z67Tg0phNBoa" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED, width: 2560)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    channelDesktopImage: contentfulMedia(
      contentful_id: { eq: "22LaKuJnrvyuKUzPO28Gb5" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED, width: 2560)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    channelMobileImage: contentfulMedia(
      contentful_id: { eq: "2NJv1sn8An68vUKoW0zq5X" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED, width: 2560)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    sportsImage: contentfulMedia(
      contentful_id: { eq: "1G0go4qS4U8Q5msZ4pkqiv" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED, width: 2560)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    motherSonImage: contentfulMedia(
      contentful_id: { eq: "50srECuc8dq0hjCy1GVm87" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED, width: 2560)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    allContentfulHeader(
      filter: {
        identifier: {}
        id: { eq: "c72cbf4a-ddd3-5cf8-bc6f-60ae8af49a80" }
      }
    ) {
      edges {
        node {
          id
          __typename
          identifier
          navigationPosition
          hideLocation
          navigationPosition
          headerBackgroundColor
          headerTextColor
          topBarBackgroundColor
          topBarTextColor
          navigationBackgroundColor
          navigationTextColor
          hoverBackgroundColor
          hoverTextColor
          logoLink
          borderColor
          borderHeight
          textBlockAlignment
          textBlock {
            raw
          }
          linkGroups {
            ...LeshenLinkGroup
            ...LeshenNavigationLink
          }
          topBarLinks {
            ...LeshenNavigationLink
          }
          callToActions {
            ... on ContentfulButton {
              ...LeshenButton
            }
            ... on ContentfulPhoneNumber {
              ...LeshenPhoneNumber
            }
          }
          logo {
            cloudinary {
              gatsbyImageData(placeholder: BLURRED)
              svg {
                code
                presentationWidth
              }
              context {
                custom {
                  alt
                  caption
                }
              }
            }
          }
        }
      }
    }
    allContentfulFooter(
      filter: { id: { eq: "afaa43f7-6d91-56e9-ac4b-47edab6bfc1f" } }
    ) {
      edges {
        node {
          __typename
          id
          identifier
          linkGroups {
            ...LeshenLinkGroup
          }
          logo {
            cloudinary {
              gatsbyImageData(placeholder: BLURRED)
              svg {
                code
                presentationWidth
              }
              context {
                custom {
                  alt
                  caption
                }
              }
            }
          }
          disclaimers {
            ...LeshenDisclaimer
          }
          navigationBackgroundColor
          navigationTextColor
          disclaimerBackgroundColor
          disclaimerTextColor
          logoLink
          logoBackgroundColor
          logoTextColor
          logoTopBorder
          logoBottomBorder
        }
      }
    }
  }
`
